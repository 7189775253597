export default function BookingButton2({
  text = "Termin buchen",
  classes,
  style,
}) {
  //   const myStyle = {
  //     position: "fixed",
  //     top: "180px",
  //     right: 0,
  //     display: "block",
  //     textAlign: "center",
  //     opacity: 0.85,
  //     backgroundColor: "#053e51",
  //     color: "#ffffff",
  //     fontSize: "14px",
  //     overflow: "hidden",
  //     zIndex: 20000,
  //     width: "auto",
  //     borderRadius: "4px 0 0 4px",
  //     textDecoration: "none",
  //     padding: "10px",
  //     lineHeight: 1.4,
  //   };

  return (
    <a
      href="https://www.doctolib.de/praxis/braunschweig/fliegender-zahn-kinderzahnheilkunde-mit-kieferorthopaedie-in-braunschweig"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button
        rel="noopener noreferrer"
        target="_blank"
        className={`btn ${classes}`}
      >
        <span>
          Termine buchen & <br />
          Anfragen senden
        </span>
        <br />
        <img
          style={{
            height: "15px",
            marginTop: "10px",
            verticalAlign: "middle",
            width: "auto",
          }}
          src="https://www.doctolib.de/external_button/doctolib-white-transparent.png"
          alt="Doctolib"
        />
      </button>
    </a>
  );
}
