import { forwardRef, useImperativeHandle, ReactNode, useRef } from "react";
import CarouselComponent from "react-multi-carousel";
import CarouselRef from "react-multi-carousel/lib/types";
import "react-multi-carousel/lib/styles.css";
// import { CarouselLeftButton, CarouselRightButton } from "../../buttons";

import "./react-multi-carousel.scss";
import "./carousel.scss";

// The forwardRef function allows the parent component to access methods of this component
export const Carousel = forwardRef(
  (
    {
      itemsToDisplay = [1, 1, 1, 1],
      showDots = true,
      classes,
      children,
      afterChange,
      itemsLength = 0,
      showNormalArrowsOnMobile = false,
    },
    ref
  ) => {
    const speed = 3000;

    const defaultBreakpointItems = {
      desktop: {
        breakpoint: { max: 5000, min: 1366 },
        items: itemsToDisplay[3],
      },
      smallLaptop: {
        breakpoint: { max: 1366, min: 768 },
        items: itemsToDisplay[2],
      },
      tablet: {
        breakpoint: { max: 768, min: 375 },
        items: itemsToDisplay[1],
      },
      mobile: {
        breakpoint: { max: 375, min: 0 },
        items: itemsToDisplay[0],
      },
    };

    // const carouselRef = useRef < CarouselRef > null;

    // const handleLeftButtonClick = () => {
    //   if (carouselRef.current) {
    //     carouselRef.current.previous(1);
    //   }
    // };

    // const handleRightButtonClick = () => {
    //   if (carouselRef.current) {
    //     carouselRef.current.next(1);
    //   }
    // };

    // // This exposes the handlers to parent components
    // useImperativeHandle(ref, () => ({
    //   handleLeftButtonClick,
    //   handleRightButtonClick,
    // }));

    return (
      <div className="wrapper">
        {/* <CarouselLeftButton
          onClick={handleLeftButtonClick}
          classes={`carousel__left-button ${
            !showDots ? "carousel__left-button__no-dots" : ""
          }
          ${showNormalArrowsOnMobile ? "carousel__left-button--shown" : ""}
          `}
        /> */}
        <div
          className={[
            "carousel",
            !showDots ? "carousel__no-dots" : "",
            classes,
          ].join(" ")}
        >
          <CarouselComponent
            responsive={defaultBreakpointItems}
            renderDotsOutside={true}
            autoPlay
            infinite={true}
            showDots={false}
            // arrows={false}
            renderButtonGroupOutside={true}
            autoPlaySpeed={speed}
            beforeChange={afterChange}
            // ref={carouselRef}
            slidesToSlide={1}
            // customButtonGroup={
            //   !showNormalArrowsOnMobile ? (
            //     <div className="carousel__button-group">
            //       <CarouselLeftButton onClick={handleLeftButtonClick} />
            //       <div
            //         style={{
            //           height: 10,
            //           width: `${19.2 * itemsLength}px`,
            //         }}
            //       />
            //       <CarouselRightButton onClick={handleRightButtonClick} />
            //     </div>
            //   ) : null
            // }
          >
            {children}
          </CarouselComponent>
        </div>
        {/* <CarouselRightButton
          onClick={handleRightButtonClick}
          classes={`carousel__right-button ${
            !showDots ? "carousel__right-button__no-dots" : ""
          }
          ${showNormalArrowsOnMobile ? "carousel__left-button--shown" : ""}
          
          `}
        /> */}
      </div>
    );
  }
);
