import React from "react";

import landingOne from "../../images/landing/landing-1.jpg";
import landingTwo from "../../images/landing/landing-2.jpg";
import { Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../useWindowDimensions";

export default function Landing({ setSelectedFlow }) {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const handleImpressum = () => {
    setSelectedFlow("ortho");
    navigate("/rechtliches");
  };

  return (
    <div className="landing">
      <div onClick={() => setSelectedFlow("ortho")} className="image-container">
        <img src={landingTwo} />
        <div className="overlay">
          <div className="overlay__container">
            <p className="overlay__left">Kieferorthopädie</p>
            <p className="overlay__subtext">für jedes Alter</p>
          </div>
        </div>
      </div>
      <div
        onClick={() => setSelectedFlow("pediatric")}
        className="image-container"
      >
        <img src={landingOne} />
        <div className="overlay">
          <div className="overlay__container">
            <p>Kinder- und Jugendzahnheilkunde</p>
            <p className="overlay__subtext">von 4 bis 17 Jahre</p>
          </div>
          {width < 998 && (
            <div
              onClick={() => setSelectedFlow("ortho")}
              className="datenschutz datenschutz__mobile"
            >
              <Link to="/rechtliches" style={{ marginBottom: 0 }}>
                Impressum und Datenschutz
              </Link>
            </div>
          )}
        </div>
      </div>
      {width >= 998 && (
        <div
          onClick={handleImpressum}
          className="datenschutz datenschutz__desktop"
        >
          <Link to="/rechtliches" style={{ marginBottom: 0 }}>
            Impressum und Datenschutz
          </Link>
        </div>
      )}
    </div>
  );
}
