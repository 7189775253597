import React from "react";

export default function BookingButton({
  text = "Termin buchen",
  classes,
  style,
}) {
  return (
    <button
      style={style}
      className={`btn ${classes}`}
      onClick={() => {
        window.open(
          "https://www.doctolib.de/praxis/braunschweig/fliegender-zahn-kinderzahnheilkunde-mit-kieferorthopaedie-in-braunschweig",
          "_blank"
        );
      }}
    >
      {text}
    </button>
  );
}
