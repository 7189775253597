import React from "react";
import Banner from "../elements/Banner";

import anesthesia from "../../images/anesthesia.jpg";
import lachgas from "../../images/lachgas.jpg";
import dog from "../../images/dog.jpg";

import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function Sedation() {
  return (
    <React.Fragment>
      <PageTitle title="Therapiehund und Sedierung" canonical="sedierung" />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Therapiehund und Sedierung" />

          <div className="container">
            <div className="orange-container mt-50 ">
              <h4 className="text-secondary " style={{ paddingLeft: "2rem" }}>
                Therapiehund
              </h4>
              <div className="diagnostic-text-with-image--reverse d-flex border-0">
                <div>
                  <p className="text-justify">
                    Bereit zum Abheben? In unserer Praxis erwartet Ihre Kinder
                    ein ganz besonderes Crew-Mitglied: unser Therapiehund Polly,
                    der selbst nur drei Beine hat, aber voller Mut und
                    Lebensfreude steckt. Keine Sorge, sie hat ihre eigene Start-
                    und Landebahn, direkt neben dem Behandlungsstuhl!
                  </p>

                  <p className="text-justify">
                    Hat Ihr Kind Flugangst, wenn es um zahnärztliche Besuche
                    geht? Unser dreibeiniger Co-Pilot lenkt ihre*seine
                    Aufmerksamkeit von den Wolken (und den Bohrern) ab und sorgt
                    dafür, dass Ihr Kind sich bei der Landung in unserem
                    Behandlungsstuhl rundum wohlfühlt. Mit ihrem bezaubernden
                    Lächeln und ihrer unvergleichlichen Art, sich auf nur drei
                    Pfoten durchs Leben zu schlagen, hilft Polly Ihrem Kind,
                    auch die holprigsten Turbulenzen zu meistern.
                  </p>
                  <p className="text-justify">
                    Unser Therapiehund Polly ist nicht nur ein flauschiger
                    Begleiter, sondern auch ein echter "Zahn-Flüsterer". Sie
                    weiß genau, wie sie unsere Passagiere beruhigen kann, sodass
                    Ihr Kind sich während Ihrer zahnärztlichen Reise ganz
                    entspannt zurücklehnen kann. Keine Zahnfee könnte das besser
                    hinbekommen!
                  </p>
                  <p className="text-justify">
                    Und keine Sorge, die Kosten für diesen besonderen Service
                    sind keine astronomischen Höhenflüge. Ab rund 150 € kann Ihr
                    Kind gemeinsam mit unserem dreibeinigen Helden in eine
                    angstfreie Zukunft starten. Bei uns landen ihre*seine Zähne
                    sicher!
                  </p>
                </div>
                <img
                  src={dog}
                  style={{
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>

            <div className="orange-container mt-50">
              <h4 className="text-secondary" style={{ paddingLeft: "2rem" }}>
                Lachgassedierung
              </h4>
              <div className="diagnostic-text-with-image border-0">
                <div>
                  <p className="text-justify">
                    Lachgas, auch als Lachgasanästhesie oder Lachgassedierung
                    bekannt, ist eine Form der Sedierung, die in der Zahnmedizin
                    häufig angewendet wird, um Patienten während zahnärztlicher
                    Eingriffe zu entspannen und Schmerzen zu reduzieren. Es
                    handelt sich um eine sichere und effektive Methode, die
                    sowohl für Kinder als auch für Erwachsene geeignet ist.
                  </p>
                  <p className="text-justify">
                    Die Verabreichung von Lachgas erfolgt über eine Maske, die
                    über Nase und Mund des Patienten platziert wird. Das Gas
                    wird zusammen mit Sauerstoff eingeatmet, wodurch eine
                    angenehme Entspannung und ein Gefühl der Euphorie entstehen.
                    Dabei bleibt der Patient jedoch bei vollem Bewusstsein und
                    kann auf Anweisungen des Zahnarztes reagieren.
                  </p>
                  <p className="text-justify">
                    Ein großer Vorteil von Lachgas ist seine schnelle Wirkung
                    und die Möglichkeit, die Sedierung während des gesamten
                    Eingriffs zu regulieren. Nach Abschluss des Eingriffs wird
                    das Lachgas abgestellt, und der Patient erholt sich schnell
                    und ohne Nebenwirkungen.
                  </p>
                  <p className="text-justify">
                    Lachgas eignet sich besonders für Patienten mit leichter
                    Zahnarztangst oder Unbehagen vor zahnärztlichen
                    Behandlungen. Es ermöglicht eine stressfreie Erfahrung und
                    trägt dazu bei, dass Patienten regelmäßig ihre
                    zahnärztlichen Termine wahrnehmen, was langfristig zu einer
                    besseren Mundgesundheit führt.
                  </p>
                  <p className="text-justify">
                    Die Sedierung ist eine reine Privatleistung und kostet in
                    der Regel ab rund 150 €.
                  </p>
                </div>
                <img
                  src={lachgas}
                  style={{
                    borderRadius: "2rem",
                    marginLeft: "2rem",
                    maxWidth: "50%",
                  }}
                />
              </div>
            </div>

            <div className="orange-container mt-50">
              <h4 className="text-secondary" style={{ paddingLeft: "2rem" }}>
                Sedierung
              </h4>

              <div className="diagnostic-text-with-image--reverse d-flex border-0">
                <div>
                  <p className="text-justify">
                    Wenn Ihr Kind für eine normale Behandlung am Stuhl
                    überfordert ist, bieten wir die Möglichkeit einer Sedierung.
                    Dabei wird ein angstlösendes, dämpfendes Medikament
                    (Midazolam) verabreicht, das auch das Erinnerungsvermögen
                    für kurze Zeit ausschaltet, sodass die Behandlung für Ihr
                    Kind erträglich ist, ohne es zu traumatisieren.
                  </p>

                  <p className="text-justify">
                    Ihr Kind bleibt während der gesamten Sedierung ansprechbar
                    und wird nach der Behandlung wie nach einer Narkose für eine
                    gewisse Zeit in der Praxis überwacht. Gerne besprechen wir
                    mit Ihnen, ob eine Behandlung unter Sedierung bei Ihrem Kind
                    ratsam ist.
                  </p>
                  <p className="text-justify">
                    Die Sedierung ist eine reine Privatleistung und kostet in
                    der Regel ab rund 150 €.
                  </p>
                </div>
                <img src={anesthesia} />
              </div>
            </div>
          </div>
          <BookingButton
            classes="btn-primary mt-60 d-block mx-auto"
            text="Hier geht es zur Terminbuchung"
          />
        </section>
      </div>
    </React.Fragment>
  );
}
