import React, { useContext } from "react";
import Banner from "../elements/Banner";

import uvLight from "../../images/uv-light.jpg";
import cement from "../../images/cement.jpg";
// import teethFilling from "../../images/teeth-filling.jpg"
import boyTeeth from "../../images/boy-teeth.jpg";
import wurzelbehandlungen from "../../images/wurzelbehandlungen.jpg";
// import jaw from "../../images/jaw-model.jpg";
import zahnunfall from "../../images/zahnunfall.jpg";
import paintTooth from "../../images/tooth-paint-2.png";
import braces from "../../images/about/braces.jpg";
import service6 from "../../images/services/services5.jpg";
import BookingButton from "../elements/BookingButton";
import lokale from "../../images/lokale.jpg";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";
import PageTitle from "../elements/PageTitle";

export default function Treatment() {
  const { setSelectedFlow } = useContext(Context);
  const navigate = useNavigate();
  const handleChangeWebsite = () => {
    setSelectedFlow("pediatric");
    navigate("/");
  };
  return (
    <React.Fragment>
      <PageTitle
        title="Behandlungen"
        description="Kariesbehandlung ohne Bohren"
        canonical="behandlungen"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Behandlungen" />

          <div className="container">
            <div
              className="mt-50"
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
            >
              <h3 className="mt-30 text-center">
                Kariesbehandlung ohne Bohren
              </h3>
              <h4 className="mt-30 text-secondary">Icon Approximal</h4>

              <p>
                <u>
                  Frühzeitige, schonende Kariesbehandlung mit der
                  Infiltrationsmethode
                </u>
                <br />
                <br />
                Karies beginnt oft im Zahnzwischenraum, weil hier die Zahnbürste
                schlecht hinkommt. Die betroffenen Stellen sind nicht immer
                leicht zu behandeln. Und ab einem bestimmten Stadium der Karies
                bleibt leider wenig anderes übrig, als zu bohren.
                <br />
                <br />
                Wird die Karies aber früh erkannt, können wir Ihnen eine andere,
                schonende Lösung anbieten: Die sogenannte
                Infiltrationsbehandlung mit einem speziellen flüssigen
                Kunststoff.
              </p>

              <p>
                <strong>Die Vorteile der Infiltrationsbehandlung</strong>
                <ul className="ml-40">
                  <li>Frühzeitiger Karies-Stopp – ohne Bohrer</li>
                  <li>Gesunde Zahnsubstanz wird geschont</li>
                  <li>Der Zahn lebt länger</li>
                </ul>
              </p>

              <div style={{ position: "relative" }}>
                <iframe
                  title="video"
                  className="mx-auto d-block"
                  src="
https://player.vimeo.com/video/80063171?h=611eceb0ed"
                  width="100%"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <p className="text-center mt-10">
                <a
                  href="
https://vimeo.com/80063171"
                >
                  Patientenvideo &bdquo;Approximalkaries schonend behandeln mit
                  Icon&ldquo;
                </a>{" "}
                from{" "}
                <a
                  href="
https://vimeo.com/user13338388"
                >
                  DMG
                </a>
                on{" "}
                <a
                  href="
https://vimeo.com"
                >
                  Vimeo
                </a>
                .
              </p>

              <h4 className="text-secondary mt-50">Icon Vestibular</h4>
              <p className="mt-20">
                <strong>
                  Flecken auf den Zähnen ästhetisch und schonend behandeln.
                </strong>
              </p>
              <p>
                <strong>Beginnende Karies sorgt für weißliche Flecken</strong>
                <br />
                Solche sogenannten „White Spots“ auf den Zahnflächen treten oft
                nach der Entfernung festsitzender Zahnspangen auf. Lange gab es
                hierfür keine wirklich befriedigende Behandlungsmöglichkeit.
              </p>

              <p>
                <strong>Nicht jeder Fleck ist kariesbedingt</strong>
                <br />
                Auch die Schmelzkrankheit Fluorose oder entwicklungsbedingte
                Schmelzschädigungen können Verfärbungen verursachen. Was kann
                man hier tun?
              </p>

              <p>
                <strong>Herkömmliche Methoden sind oft nicht passend</strong>{" "}
                <br />
                Bleaching allein führt in den meisten Fällen nicht zu einem
                dauerhaft überzeugenden Ergebnis. Andere, effektivere
                Behandlungsmethoden sind dagegen oft relativ aufwändig, wie z.B.
                Veneerversorgungen.
              </p>

              <div style={{ position: "relative" }}>
                <iframe
                  title="video-2"
                  className="mx-auto d-block mt-30"
                  src="https://player.vimeo.com/video/80063442?h=ba66956614"
                  width="100%"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <p className="text-center mt-10">
                <a href="https://vimeo.com/80063442">
                  Patientenvideo &bdquo;White Spots &auml;sthetisch behandeln
                  mit Icon&ldquo;
                </a>{" "}
                from <a href="https://vimeo.com/user13338388">DMG</a> on{" "}
                <a href="https://vimeo.com">Vimeo</a>.
              </p>

              <strong>
                <p style={{ fontWeight: "700px" }} className="mt-30">
                  Diese neuartige Behandlungsmethode wird leider nicht von den
                  gesetzlichen Krankenversicherungen getragen und kostet ca. 249
                  € bei einer Zahnfläche bzw. ca. 449 € bei zwei Zahnflächen.
                </p>
              </strong>
            </div>
            <BookingButton
              classes="btn-primary mt-50  d-block mx-auto"
              text="Hier geht es zur Terminbuchung"
            />

            <div
              className="mt-50"
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
            >
              <h4 className="mt-30 text-secondary">Lokale Betäubung</h4>
              <div className="diagnostic-text-with-image mt-30 border-0">
                <div>
                  <p className="text-justify">
                    Für eine möglichst schmerzfreie Behandlung verwenden wir in
                    unserer Praxis häufig eine lokale Betäubung, um den zu
                    behandelnden Zahn "schlafen zu schicken". Vor den
                    herkömmlichen Spritzen verwenden wir ein "Zauberspray", um
                    die Einstichstelle vorab zu betäuben und unangenehme Gefühle
                    zu reduzieren. Letzteres ist keine Leistung der gesetzlichen
                    Krankenkasse und kostet in der Regel zwischen 10 und 20
                    Euro.
                  </p>

                  <p className="text-justify">
                    Bei kleinen Kindern kann die schnelle Taubheit der Lippe bei
                    der ersten Betäubung irritierend sein, und sie könnten das
                    Gefühl haben, dass ihnen etwas "weh tut". Wir erklären dann,
                    dass die Taubheit ein gutes Zeichen dafür ist, dass der Zahn
                    bereits betäubt ist.
                  </p>
                  <p className="text-justify">
                    Nach der Behandlung hält das Taubheitsgefühl noch etwa 1-2
                    Stunden an, was das Risiko birgt, dass sich das Kind
                    versehentlich in die Lippe beißt. Daher sollte das Kind erst
                    wieder essen, wenn die Taubheit vollständig abgeklungen ist.
                  </p>
                </div>
                <img src={lokale} />
              </div>
            </div>

            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h3 className="text-center mt-30">Füllungen</h3>
              <p className="mt-20">
                Wenn Karius und Baktus den Zahn „ausgehöhlt“ haben, muss die
                Karies entfernt und der Zahn mit einer Füllung versorgt werden.
                Dafür gibt es verschiedene Materialien mit unterschiedlichen
                Eigenschaften. Wir verwenden meist Zement oder eine
                Kunststoff-Keramik (Komposit).
              </p>

              <div className="diagnostic-text-with-image border-0">
                <div>
                  <h4 className="mt-30 text-secondary ">
                    Zement (Glasionomer-Zement):
                  </h4>
                  <p className="text-justify">
                    Zementfüllungen sind eine häufig verwendete Option in der
                    Zahnarztpraxis.. Sie bestehen aus einem speziellen
                    Zementgemisch, das in den Hohlraum einer kariösen Stelle
                    eingebracht wird, um den Zahn zu stabilisieren und zu
                    schützen.
                  </p>

                  <p className="text-justify">
                    Zementfüllungen bieten einige Vorteile, darunter ihre
                    Einfachheit und ihre Fähigkeit, sich gut an die Form des
                    Zahns anzupassen. Sie sind auch kostengünstiger als einige
                    andere Füllungsoptionen.
                  </p>
                  <p className="text-justify">
                    Allerdings haben Zementfüllungen auch einige Nachteile. Sie
                    sind nicht so haltbar wie andere Materialien wie Kunststoff
                    oder Keramik, und sie können sich im Laufe der Zeit
                    verfärben oder sich lösen. Die Zementfüllung wird von der
                    gesetzlichen Krankenkasse in der Regel bezahlt.
                  </p>
                </div>
                <img
                  src={cement}
                  style={{
                    objectFit: "cover",
                    objectPosition: "left",
                  }}
                />
              </div>
              <div className="diagnostic-text-with-image--reverse mt-50 border-0">
                <div>
                  <h4 className="mt-30 text-secondary">
                    Kunststoff (Komposit):
                  </h4>
                  <p className="text-justify">
                    Komposit ist zahnfarben, biokompatibel, formstabil, hart,
                    gut polierbar und ästhetisch. Es eignet sich optimal für
                    Frontzähne, bleibende Zähne und Milchbackenzähne. Die
                    gesetzlichen Krankenkassen übernehmen die Kosten für
                    (hochwertige) Kompositfüllungen in der Regel nicht.
                    <br />
                    <br />
                    Aufgrund der deutlich höheren Langlebigkeit, der
                    Verträglichkeit sowie der Ästhetik empfehlen wir stets diese
                    hochqualitative Füllungsvariante. Schließlich möchte man so
                    selten wie möglich Zahnbehandlungen, für die man jedes Mal
                    erneut Zahnsubstanz opfern muss, durchführen.
                    <br />
                    <br />
                    Interessant zu wissen: Der Großteil des Materials besteht
                    tatsächlich aus Keramik, wird aber wegen des Zusetzens mit
                    Kunststoff umgangssprachlich Kunststofffüllung genannt.
                    Komposit oder Composite ist daher eigentlich der korrekte
                    Name.
                  </p>
                </div>
                <img src={uvLight} />
              </div>
            </div>

            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h3 className="mt-30 text-center">Kreidezähne</h3>
              <div className="diagnostic-text-with-image mt-30 border-0">
                <div>
                  <p className="text-justify">
                    Wenn Zähne mit weißlich bis gelbbraunen Flecken
                    durchbrechen, spricht man von Kreidezähnen, einer
                    entwicklungsbedingten Erkrankung des Schmelzes und Dentins.
                    Diese Zähne sind oft temperatur- und berührungsempfindlich
                    und haben ein erhöhtes Kariesrisiko. Die Behandlung richtet
                    sich nach dem Schweregrad der Erkrankung.
                  </p>
                  <p className="text-justify">
                    Betroffen sind vor allem die bleibenden ersten Backenzähne
                    und Frontzähne (Molaren-Inzisiven-Hypomineralisation, MIH),
                    aber auch Milchzähne (Milch-Molaren-Hypomineralisation, MMH)
                    können betroffen sein.
                  </p>
                </div>
                <img src={boyTeeth} />
              </div>
              <ul>
                <li className="ml-20">
                  <h6 className="mt-30 text-secondary">Ursachen:</h6>
                  <p className="text-justify">
                    Verschiedene Faktoren können die Zahnentwicklung stören:
                    Umweltgifte wie Bisphenol A oder Dioxin, Antibiotika,
                    Infektionskrankheiten, Vitamin-D-Mangel,
                    Atemwegserkrankungen und Sauerstoffmangel bei der Geburt.
                    Genetik spielt eine geringere Rolle.
                  </p>
                </li>
                <li className="ml-20">
                  <h6 className="mt-30 text-secondary">Behandlung:</h6>
                  <p className="text-justify">
                    Bei milden Formen von MIH/MMH werden die Zähne versiegelt
                    und präventive Maßnahmen gegen Karies ergriffen. Bei
                    stärkeren Schäden müssen Zähne oft gefüllt oder mit
                    kieferorthopädischen Bändern gestützt werden. Eine
                    Überkronung kann ebenfalls notwendig sein. In seltenen
                    Fällen wird ein stark erkrankter Zahn nach Absprache mit dem
                    Kieferorthopäden entfernt, um die Lücke durch aufgerückte
                    Backenzähne zu schließen. Kinderzahnärzte sind auf die
                    Behandlung von Kreidezähnen spezialisiert und können Ihrem
                    Kind gut helfen.
                  </p>
                </li>
              </ul>
            </div>

            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h3 className="mt-30 text-center">Kinderkronen</h3>
              <div className="diagnostic-text-with-image mt-30 border-0">
                <div>
                  <p className="text-justify">
                    Wurzelbehandelte Milchzähne oder solche mit großflächiger
                    Karies versorgen wir mit vorgefertigten Kinderkronen. Diese
                    schützen die Zähne vor Frakturen und erhalten Zähne mit
                    erheblichem Hartsubstanzverlust. Im Seitenzahnbereich
                    verwenden wir Kronen aus medizinischem Edelstahl oder
                    Vollkeramik, für die Frontzähne aus weißem Komposit.
                  </p>
                  <p className="text-justify">
                    Diese Kronen werden ohne Abdruck direkt dem Zahn angepasst
                    und erfordern nur einen Termin. Sie fallen mit dem Milchzahn
                    aus, wenn dieser wackelt.
                  </p>
                  <p className="text-justify">
                    Edelstahlkronen werden von der gesetzlichen Kasse bezahlt,
                    haben jedoch das Potenzial, Allergien auszulösen, unabhängig
                    dessen, dass Sie Ihr Kind sicherlich ungern mit metallischen
                    Zähnen in den Kindergarten oder in die Schule lassen wollen.
                  </p>
                  <p className="text-justify">
                    Keramikkronen sind viel unauffälliger und auch ideal für die
                    Versorgung von Milchzähnen. Allerdings übernehmen die
                    gesetzlichen Krankenkassen nicht die Kosten für diese
                    aufwändigere Versorgung. Hierbei ist mit rund 399 € je Zahn
                    zu rechnen.
                  </p>
                  <p className="text-justify">
                    Bei endgültigen Versorgungen bleibender Zähne müssen Sie bei
                    hochwertigen Materialien und Verarbeitungsvarianten mit rund
                    der doppelten Kostenhöhe rechnen.
                  </p>
                </div>
                <img src={paintTooth} className="mb-1" />
              </div>
            </div>
            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h4 className="mt-30 text-center">Wurzelbehandlungen</h4>
              <div className="diagnostic-text-with-image mt-30 border-0">
                <div>
                  <p className="text-justify">
                    Für tiefgreifende Karies in Milchzähnen mit entzündetem Nerv
                    bieten wir eine spezielle Wurzelbehandlung an. Dabei wird
                    der entzündete Nerv in der Zahnkrone entfernt, während das
                    Nervengewebe in der Wurzel erhalten bleibt, um die
                    Restvitalität zu bewahren. Zähne, die wurzelbehandelt
                    wurden, erhalten je nach Alter und Ausmaß der Karies
                    entweder eine Krone oder eine Füllung.
                  </p>
                  <p className="text-justify">
                    Bitte beachten Sie, dass Wurzelbehandlungen oftmals keine
                    Leistung der gesetzlichen Krankenversicherung darstellen.
                    Bei Milchzähnen ist regelmäßig mit Kosten von rund 100-200
                    €, bei bleibenden Zähnen von 500-2000 € zu rechnen.
                  </p>
                </div>
                <img
                  src={wurzelbehandlungen}
                  style={{
                    maxHeight: "400px",
                  }}
                />
              </div>
            </div>

            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h3 className="mt-30 text-center">Kieferorthopädie</h3>
              <div className="diagnostic-text-with-image mt-30 border-0">
                <div>
                  <h4 className="mt-30 text-secondary">Lückenhalter</h4>
                  <p className="text-justify">
                    Frühzeitiger Verlust von Milchbackenzähnen kann zu
                    Zahnwanderungen führen, was Platzverlust für bleibende Zähne
                    bedeutet. Zahnplatzhalter verhindern dies und sichern Raum
                    für nachfolgende Zähne.
                  </p>
                  <p className="text-justify">
                    Wir bieten festsitzende und herausnehmbare Zahnplatzhalter
                    an und beraten Sie gerne über ihre Vor- und Nachteile in der
                    Praxis. Bitte stellen Sie sich auch hier für die
                    bestmögliche Versorgung auf gewisse Eigenanteile ein.
                  </p>
                </div>
                <img src={service6} />
              </div>
              <div className="diagnostic-text-with-image--reverse mt-40 border-0">
                <div>
                  <h4 className=" text-secondary">Zahnfehlstellungen</h4>
                  <p className="text-justify">
                    Zahnfehlstellungen sind wie kleine Rebellionen im Mund, wenn
                    die Zähne nicht perfekt harmonieren. Einige schieben sich
                    vor, andere verstecken sich zurück. Doch jede Fehlstellung
                    hat ihre eigene Geschichte und Lösung – von Drahtspangen bis
                    zu durchsichtigen Schienen. Schauen Sie hierzu in unserer
                    Kieferorthopädie.
                  </p>
                </div>
                <img src={braces} />
              </div>
              <button
                onClick={handleChangeWebsite}
                className="btn btn-primary mx-auto d-block mt-30"
              >
                Mehr Informationen
              </button>
            </div>

            <div
              style={{
                border: "1px solid #feb354",
                borderRadius: "2rem",
                padding: "2rem",
              }}
              className="mt-50"
            >
              <h4 className="mt-30 text-center">Hilfe nach Zahnunfall</h4>
              <div
                className="diagnostic-text-with-image mt-30 border-0"
                style={{
                  alignItems: "unset",
                }}
              >
                <div>
                  <p className="text-justify">
                    Zahntraumata treten bei 50 % aller Kinder bis zum 17.
                    Lebensjahr auf, meist betroffen sind die oberen Frontzähne.
                    Es ist wichtig, nach einem Unfall sofort eine Zahnarztpraxis
                    zu kontaktieren, um die Dringlichkeit der Versorgung zu
                    klären.
                  </p>

                  <p className="text-justify">
                    Besonders häufig treten Unfälle zwischen dem 2. und 3. sowie
                    dem 7. und 11. Lebensjahr auf, wobei Jungen dreimal häufiger
                    betroffen sind als Mädchen.
                  </p>

                  <p className="text-justify">
                    Milchzähne: Bei verletzten Milchzähnen liegt der Fokus auf
                    dem gesunden Erhalt der nachfolgenden bleibenden Zähne.
                    Komplett herausgeschlagene Milchzähne werden nicht wieder
                    eingepflanzt. Milchzähne, die in den Kieferknochen geschoben
                    werden, brechen normalerweise nach einigen Wochen oder
                    Monaten wieder durch.
                  </p>

                  <p className="text-justify">
                    Bleibende Zähne: Verletzte bleibende Zähne erfordern immer
                    den Besuch eines Zahnarztes. Bei abgeschlagenen Stücken
                    sollte das Bruchstück gesucht, in wässriger Lösung
                    aufbewahrt und zum Zahnarzt mitgenommen werden. Vollständig
                    ausgeschlagene Zähne sollten nur an der Zahnkrone berührt
                    werden, um die Zellen auf der Wurzeloberfläche zu schützen.
                    Es ist wichtig, den Zahn innerhalb idealerweise 30 Minuten
                    wieder einzupflanzen oder in einer Zahnrettungsbox zu
                    lagern, um Austrocknung zu verhindern.
                  </p>
                </div>
                <img
                  src={zahnunfall}
                  style={{
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          </div>
          <BookingButton
            classes="btn-primary mt-60 d-block mx-auto"
            text="Hier geht es zur Terminbuchung"
          />
        </section>
      </div>
    </React.Fragment>
  );
}
