import React from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

// Elements
// import LatestNewsSection from "../elements/latest-news-slider";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";
import PageTitle from "../elements/PageTitle";

const Faq = () => {
  const first = [
    {
      heading: "Ist die Behandlung schmerzhaft?",
      items: [
        `Am Tag des Eingliederns oder beim Stellen der Schraube der herausnehmbaren Spange kann es je nach Art zu einem "ziehenden Gefühl" oder zu einer "leichten Spannung" kommen. Echte Schmerzen sind sehr selten.`,
        `Außerdem braucht die Schleimhaut ein wenig Zeit, um sich an die neue Situation zu gewöhnen. Es kann also auch mal zu einer Reizung der Wange kommen.`,
      ],
    },

    {
      heading: "Wie viele Stunden am Tag muss ich die Zahnspange tragen?",
      items: [
        "Manche Zahnspangen trägt man immer außerhalb der Schule, mindestens jedoch 16 Stunden am Tag.",
        "Manche Zahnspangen muss man dauerhaft drin belassen.",
        "Wie viele Stunden die Zahnspange getragen werden muss, teilen wir bei der Eingliederung mit.",
      ],
    },
    {
      heading: "Darf ich mit der Zahnspange essen und/oder trinken?",
      items: [
        "Nein, die Zahnspange muss vor jeder Mahlzeit herausgenommen werden und anschließend nach gründlicher Reinigung der Zähne wieder eingesetzt werden. So ist ein normaler Alltag möglich.",
      ],
    },

    {
      heading: "Muss ich die Zahnspange reinigen?",
      items: [
        "Ja. Wie die regelmäßige Zahpflege, gehört die Pflege der Zahnspange zur täglichen Routine dazu.",
        "Die Zahnspange wird am besten mithilfe einer Prothesen-Zahnbürste nach jeder Mahlzeit vorsichtig unter fließendem Wasser ohne Zahnpasta von innen und außen abgebürstet.",
        "Zusätzlich sollte die Zahnspange ein Mal am Tag mit speziellen Zahnspangenreiniger-Tabs aus der Drogerie oder Apotheke gereinigt werden.",
      ],
    },
    {
      heading: "Leidet meine Aussprache, wenn ich die Zahnspange trage?",
      items: [
        "Wie bei allen kieferorthopädischen Geräten muss man mit einer Eingewöhnungszeit rechnen. Gerade bei den herausnehmbaren Zahnspangen kommt es aber fast immer, zumindest anfangs, zu kleinen oder größeren Einschränkungen bei der Sprache.",
      ],
    },
    {
      heading: "Kann ich die Kosten in Raten abbezahlen?",
      items: [
        "Ja, mit unserer Abrechnungspartnerin BFS health finance haben Sie die Möglichkeit, jede einzelne Rechnung bis zu 24 Monate zinsfrei und bis zu 72 Monate mit banküblichen Zinsen zu strecken.",
      ],
    },
    {
      heading:
        "Was soll ich machen, wenn sich etwas an der Zahnspange gelöst oder verbogen hat?",
      items: [
        `Bitte am besten online eine zeitnahe "Kurze Kontrolle" ausmachen oder uns anrufen. Bis dahin wird die Zahnspange weiter getragen.`,
        `Falls die Zahnspange nicht mehr passen sollte, uns bitte anrufen, um einen schnellstmöglichen Termin in der Praxis zu erhalten.`,
      ],
    },
    {
      heading:
        "Was soll ich machen, wenn die Zahnspange verloren oder kaputt gegangen ist?",
      items: [
        `Bitte am besten online eine zeitnahe "Kurze Kontrolle" ausmachen oder uns anrufen.`,
        `Meistens müssen wir eine neue Zahnspange herstellen lassen. Selten können wir schon zu der ggf. geplanten festen Zahnspange switchen.`,
      ],
    },
    {
      heading: "Ich habe Schmerzen. Was soll ich machen?",
      items: [
        `Bei leichten, aufschiebbaren Beschwerden bitte am besten online eine zeitnahe "Kurze Kontrolle" ausmachen oder uns anrufen. Bis dahin bitte die Zahnspange weiter tragen.`,
        `Bei starken, unaufschiebbaren Beschwerden uns bitte anrufen und die Zahnspange erst einmal weglassen.`,
      ],
    },
  ];

  const second = [
    {
      heading: "Ist die Behandlung schmerzhaft?",
      items: [
        "Am Tag des Eingliederns der festen Spange kann es je nach Bogenart zu einem 'ziehenden Gefühl' oder zu einer 'leichten Spannung' kommen. Bei stärkeren Beschwerden soll ruhig auf ein Schmerzmittel zurückgegriffen werden.",
        "Außerdem braucht die Schleimhaut ein wenig Zeit, um sich an die neue Situation zu gewöhnen. Es kann also auch mal zu einer Reizung der Wange kommen. Hierfür geben wir stets kieferorthopädisches Wachs mit, womit die Brackets abgedeckt werden können.",
        "Unsere modernen superelastischen, thermoaktiven Nickel-Titan-Bögen, die wir jeder Person anbieten, haben die Eigenschaft, sich immer in die ursprünglich vorgegebene ideale Zahnbogenform zurückstellen zu wollen, sodass dadurch die Kräfte auf die zu bewegenden Zähne kontinuierlich übertragen werden. Die Behandlung wird somit kürzer, schonender und angenehmer.",
      ],
    },
    {
      heading: "Darf ich mit den Brackets normal essen und trinken?",
      items: [
        "Den Verzehr von hartem Essen wie ganze Karotten können wir nicht empfehlen, da Brackets eine solche Belastung nicht standhalten und sich somit vom Zahn lösen können. Unser Tipp: Alles, was hart ist, in kleine Stücke schneiden und vorsichtig zerkauen. Das Abbeißen von weichem Essen ist problemlos möglich.",
      ],
    },
    {
      heading: "Wie putze ich meine Brackets optimal?",
      items: [
        "Die vielen Nischen bei Brackets erleichtern die Ansammlung von Belag und erschweren die optimale Zahnpflege. Bracket-Patient*innen haben deswegen während der Behandlung ein erhöhtes Risiko für Karies und Zahnfleischerkrankungen. Wir empfehlen eine elektrische Zahnbürste für die optimale Reinigung der Zähne und Brackets. Für die Reinigung der Zahnzwischenräume empfehlen wir kleine Interdentalbürstchen und Superfloss-Zahnseide.",
      ],
    },
    {
      heading: "Leidet meine Aussprache unter den Brackets?",
      items: [
        "Wie bei allen kieferorthopädischen Geräten muss man mit einer Eingewöhnungszeit rechnen. Der Großteil aller Patient*innen hat aber von Anfang an kaum Probleme mit der Aussprache.",
      ],
    },
    {
      heading: "Wird man die Brackets sehen können?",
      items: [
        "Wir legen einen hohen Wert auf Ästhetik und ermöglichen unseren Patient*innen auf Wunsch eine fast unsichtbare Behandlung mithilfe durchsichtiger Kunststoff-Brackets und hellweißer Bögen. Dass eine Zahnspange getragen wird, ist aber auch bei dieser hochästhetischen Variante nicht vermeidbar.",
      ],
    },
    {
      heading: "Kann ich die Kosten in Raten abbezahlen?",
      items: [
        "Ja, mit unserer Abrechnungspartnerin BFS health finance haben Sie die Möglichkeit, jede einzelne Rechnung bis zu 24 Monate zinsfrei und bis zu 72 Monate mit banküblichen Zinsen zu strecken.",
      ],
    },
    {
      heading: "Wie oft soll ich die Gummizüge wechseln?",
      items: [
        "Neue Gummizüge werden nach jeder Mahlzeit und Zahnpflege frisch eingesetzt.",
      ],
    },
    {
      heading: "Ich habe keine Gummizüge mehr, was soll ich machen?",
      items: [
        "Einfach während unserer Sprechzeiten in die Praxis kommen, wir geben gern direkt welche aus. Am unkompliziertesten geht das, wenn die leere Packung mitgenommen wird.",
      ],
    },
    {
      heading:
        "Was soll ich machen, wenn sich ein Bracket, Band oder Bogen gelöst hat oder kaputt gegangen ist?",
      items: [
        "Bitte am besten online eine zeitnahe 'Kurze Kontrolle' ausmachen oder uns anrufen.",
        "Falls das Bracket/Band scheuert oder der Bogen piksen sollte, bis dahin bitte mit dem mitgegebenen Wachs abdecken.",
        "Falls der Bogen sich komplett gelöst haben und sich in das Zahnfleisch eingebohrt haben sollte, bitte bis dahin vorsichtig mit einem Nagelknipser abschneiden.",
      ],
    },
    {
      heading: "Ich habe Schmerzen. Was soll ich machen?",
      items: [
        "Falls nur ein Bracket/Band scheuert sollte, bitte mit dem mitgegebenen Wachs abdecken, die Schleimhaut beruhigt sich nach wenigen Tagen.",
        "Bei sonstigen, leichten, aufschiebbaren Beschwerden bitte am besten online eine zeitnahe 'Kurze Kontrolle' ausmachen oder uns anrufen.",
        "Bei starken, unaufschiebbaren Beschwerden uns bitte anrufen. Etwaige erst einmal Gummizüge weglassen.",
      ],
    },
  ];

  const third = [
    {
      heading: "Ist die Behandlung schmerzhaft?",
      items: [
        "Einer der großen Vorteile der Invisalign-Zahnschienen ist die schmerzfreie Behandlung. Nur etwa 50 % unserer Patient*innen berichten, dass sie einen spürbaren Druck bei der Behandlung gespürt haben. Dieser beschränkt sich in der Regel auf die ersten Tage nach dem Schienenwechsel. Echte Schmerzen sind sehr selten.",
      ],
    },
    {
      heading: "Wie viele Stunden am Tag muss ich die Aligner tragen?",
      items: [
        "Die Aligner werden 22 Stunden täglich getragen und werden nur zum Essen sowie zur Zahnpflege und bei besonderen Anlässen herausgenommen.",
      ],
    },
    {
      heading: "Darf ich mit Aligner essen und/oder trinken?",
      items: [
        "Nein, die durchsichtigen Schienen müssen vor jeder Mahlzeit herausgenommen werden und anschließend nach gründlicher Reinigung der Zähne wieder eingesetzt werden. So ist ein normaler Alltag möglich.",
      ],
    },
    {
      heading: "Muss ich die Aligner reinigen?",
      items: [
        "Ja, sonst werden die Aligner milchig. Wenn Ihre Schienen durchsichtig bleiben sollen, müssen sie also regelmäßig sauber gemacht werden. Wie die regelmäßige Zahpflege, gehört die Pflege der Aligner zur täglichen Routine dazu.",
        "Die Aligner werden am besten mithilfe einer Prothesen-Zahnbürste nach jeder Mahlzeit vorsichtig unter fließendem Wasser ohne Zahnpasta von innen und außen abgebürstet.",
        "Bei starken Verfärbungen bitte das mitgegebene Notfall-Reinigungsset gemäß Anleitung nutzen.",
      ],
    },
    {
      heading: "Leidet meine Aussprache, wenn ich die Aligner trage?",
      items: [
        "Wie bei allen kieferorthopädischen Geräten muss man mit einer Eingewöhnungszeit rechnen. Der Großteil aller Patient*innen hat aber von Anfang an kaum Probleme mit der Aussprache.",
      ],
    },
    {
      heading: "Kann ich die Kosten in Raten abbezahlen?",
      items: [
        "Ja, mit unserer Abrechnungspartnerin BFS health finance haben Sie die Möglichkeit, jede einzelne Rechnung bis zu 24 Monate zinsfrei und bis zu 72 Monate mit banküblichen Zinsen zu strecken.",
      ],
    },
    {
      heading: "Wie oft und lange muss ich die Chewies nutzen?",
      items: [
        "Bitte 3 Mal täglich für mindestens 15 Minuten auf den Chewies wie gezeigt herum kauen.",
      ],
    },
    {
      heading: "Wie oft soll ich die Gummizüge wechseln?",
      items: [
        "Neue Gummizüge werden nach jedem Herausnehmen der Aligner frisch eingesetzt.",
      ],
    },
    {
      heading:
        "Ich habe keine Gummizüge oder Chewies mehr, was soll ich machen?",
      items: [
        "Einfach während unserer Sprechzeiten in die Praxis kommen, wir geben gern direkt welche aus. Am unkompliziertesten geht das, wenn die leere Packung mitgenommen wird.",
      ],
    },
    {
      heading:
        "Was soll ich machen, wenn sich ein Knöpfchen oder Attachment gelöst hat?",
      items: [
        "Bitte am besten online eine zeitnahe 'Kurze Kontrolle' ausmachen oder uns anrufen. Bis dahin werden die Aligner regulär weiter getragen.",
      ],
    },
    {
      heading:
        "Was soll ich machen, wenn ein Aligner verloren oder kaputt gegangen ist?",
      items: [
        "Bitte den jeweils letzten Aligner als 'Notfall-Set' einsetzen und uns entweder eine E-Mail schreiben oder anrufen. Wir bestellen Ihnen, falls notwendig, (kostenpflichtige) Ersatz-Aligner.",
      ],
    },
    {
      heading: "Ich habe Schmerzen. Was soll ich machen?",
      items: [
        "Bei leichten, aufschiebbaren Beschwerden bitte am besten online eine zeitnahe 'Kurze Kontrolle' ausmachen oder uns anrufen. Bis dahin bitte nicht auf den nächsten Aligner wechseln, jedoch den zuletzt getragenen nutzen.",
        "Bei starken, unaufschiebbaren Beschwerden uns bitte anrufen und Aligner erst einmal weglassen.",
      ],
    },
    {
      heading:
        "Ich warte gerade auf das Refinement. Wie lange muss ich die letzten Aligner tragen?",
      items: [
        "Bitte mindestens 16 Stunden täglich tragen, damit sich die Zähne nicht zurück verschieben.",
      ],
    },
  ];

  return (
    <React.Fragment>
      <PageTitle
        title="FAQ"
        description="Häufig gestellte Fragen"
        canonical="faq"
      />
      <div className="page-content bg-white">
        <div className="banner-wraper">
          <div
            className="page-banner"
            style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>FAQ</h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-home"
                        >
                          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                          <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>{" "}
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      FAQ
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
            <img className="pt-img2 animate2" src={circleDots} alt="" />
            <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
          </div>
        </div>

        <section className="section-sp3">
          <div className="container">
            <h2 className="text-center">Herausnehmbar</h2>
            <div className="row mt-20">
              <div className="col-lg-12">
                <Accordion
                  defaultActiveKey="0"
                  className="accordion ttr-accordion1"
                >
                  {first.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.heading}</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {item.items.map((item, index) => (
                            <li key={"list-item-" + index}>{item}</li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
            <h2 className="text-center mt-50">Festsitzend</h2>
            <div className="row mt-20">
              <div className="col-lg-12">
                <Accordion
                  defaultActiveKey="0"
                  className="accordion ttr-accordion1"
                >
                  {second.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.heading}</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {item.items.map((item, index) => (
                            <li key={"list-item-" + index}>{item}</li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
            <h2 className="text-center mt-50">Aligner</h2>
            <div className="row mt-20">
              <div className="col-lg-12">
                <Accordion
                  defaultActiveKey="0"
                  className="accordion ttr-accordion1"
                >
                  {third.map((item, index) => (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>{item.heading}</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          {item.items.map((item, index) => (
                            <li key={"list-item-" + index}>{item}</li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </section>

        {/* <LatestNewsSection /> */}
      </div>
    </React.Fragment>
  );
};
export default Faq;
