import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Import Images
import aboutThumb1 from "../../images/about/aktivator.jpg";
import aboutThumb2 from "../../images/about/braces.jpg";
import aboutThumb3 from "../../images/about/aligners.jpg";
import aboutThumb4 from "../../images/about/treatment.jpg";

import aboutThumbKids1 from "../../images/about/kids1.jpg";
import aboutThumbKids3 from "../../images/about/kids2.jpeg";
import aboutThumbKids2 from "../../images/about/kids3.jpg";
import aboutThumbKids4 from "../../images/about/kids4.png";

import ptImg1 from "../../images/shap/wave-orange.png";
import ptImg2 from "../../images/shap/circle-small-blue.png";
import ptImg4 from "../../images/shap/square-dots-orange.png";
import ptImg5 from "../../images/shap/square-blue.png";

import { Context } from "../../context";

const floss = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>floss</title>
    <g id="floss">
      <path d="M15.47,23.35A5.88,5.88,0,0,0,13.33,23c-1,0-3.33.4-3.33,4.1,0,2,1.06,3.14,1.36,4.37.17.69.15,2.78.44,4.18a1.66,1.66,0,0,0,3.23.14,8.27,8.27,0,0,1,1-2.36c1,1.45.71,3.65,2.64,3.57s1.73-2.12,1.84-3.93a5.48,5.48,0,0,1,.68-2.91A6.83,6.83,0,0,0,22,27.1c0-3.7-2.33-4.1-3.33-4.1C16.5,23,16.43,23.74,15.47,23.35Zm1.81,1.86A3.67,3.67,0,0,1,18.67,25c.69,0,1.33.25,1.33,2.1,0,1.43-.94,2.39-1.31,3.9a22.88,22.88,0,0,0-.27,2.83c-1.34-3.45-3.5-3.46-4.84,0A23.28,23.28,0,0,0,13.31,31C12.94,29.49,12,28.53,12,27.1,12,23.15,14.88,26.17,17.28,25.21Z"></path>
      <path d="M1,8h9.38l.73,1.45c.37.74.15.55,8.89.55.73,0,.84-.44,1.62-2H31a1,1,0,0,0,1-1V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V7A1,1,0,0,0,1,8ZM2,4A2,2,0,0,1,4,2H28a2,2,0,0,1,2,2V6c-10,0-9.52-.19-9.89.55L19.38,8H12.62c-.81-1.61-.88-2-1.62-2H2Z"></path>
      <path d="M44,12H21c-.73,0-.84.44-1.62,2H12.62l-.73-1.45C11.51,11.79,11.69,12,1,12a1,1,0,0,0-1,1C0,30.82-.24,31.25,1.52,43.71A5,5,0,0,0,6.47,48H25.53a5,5,0,0,0,4.95-4.29C32.23,31.38,32,30.67,32,14H44a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2H42a4,4,0,0,0-4,4v4a4,4,0,0,0,4,4h1a1,1,0,0,0,0-2H42a2,2,0,0,1-2-2V26a2,2,0,0,1,2-2h2a4,4,0,0,0,4-4V16A4,4,0,0,0,44,12ZM28.5,43.43a3,3,0,0,1-3,2.57H6.47a3,3,0,0,1-3-2.57C1.77,31.22,2,30.62,2,14h8.38l.73,1.45c.37.74.15.55,8.89.55.73,0,.84-.44,1.62-2H30C30,30.84,30.23,31.24,28.5,43.43Z"></path>
      <path d="M4,30A12,12,0,1,0,16,18,12,12,0,0,0,4,30Zm22,0A10,10,0,1,1,16,20,10,10,0,0,1,26,30Z"></path>
    </g>
  </svg>
);
const computer = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>computer with teeth data</title>
    <g id="computer_with_teeth_data" data-name="computer with teeth data">
      <path d="M45,0H3A3,3,0,0,0,0,3V36a3,3,0,0,0,3,3H18.93a5.74,5.74,0,0,1-1.37,3H17a3,3,0,0,0-3,3v2a1,1,0,0,0,1,1H33a1,1,0,0,0,1-1V45a3,3,0,0,0-3-3h-.56a5.74,5.74,0,0,1-1.37-3H45a3,3,0,0,0,3-3V3A3,3,0,0,0,45,0ZM3,2H45a1,1,0,0,1,1,1V32H2V3A1,1,0,0,1,3,2ZM32,45v1H16V45a1,1,0,0,1,1-1H31A1,1,0,0,1,32,45Zm-4-3H20a7.72,7.72,0,0,0,1-3H27A7.72,7.72,0,0,0,28,42Zm17-5H3a1,1,0,0,1-1-1V34H46v2A1,1,0,0,1,45,37Z"></path>
      <path d="M5,30H43a1,1,0,0,0,1-1V5a1,1,0,0,0-1-1H5A1,1,0,0,0,4,5V29A1,1,0,0,0,5,30ZM6,6H42V28H6Z"></path>
      <path d="M17.67,10c-2.17,0-2.24.74-3.2.35A5.88,5.88,0,0,0,12.33,10c-1,0-3.33.4-3.33,4.1,0,2,1.06,3.14,1.36,4.37.17.69.15,2.78.44,4.18a1.66,1.66,0,0,0,3.23.14,8.27,8.27,0,0,1,1-2.36c1,1.45.71,3.65,2.64,3.57s1.73-2.12,1.84-3.93a5.48,5.48,0,0,1,.68-2.91A6.83,6.83,0,0,0,21,14.1C21,10.4,18.67,10,17.67,10Zm.68,6.31c-.91,1.91-.73,2.09-.93,4.52-1.34-3.45-3.5-3.46-4.84,0-.19-2.36,0-2.6-.93-4.52A5,5,0,0,1,11,14.1c0-3.95,2.88-.93,5.28-1.89A3.67,3.67,0,0,1,17.67,12c.69,0,1.33.25,1.33,2.1A5,5,0,0,1,18.35,16.31Z"></path>
      <path d="M38,10H35V9a1,1,0,0,0-1-1H30a1,1,0,0,0-1,1v3H26a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H38a1,1,0,0,0,1-1V11A1,1,0,0,0,38,10ZM27,14h2v2H27Zm4-4h2v6H31Zm6,6H35V12h2Z"></path>
      <path d="M26.71,20.29a1,1,0,0,0-1.42,1.42A1,1,0,0,0,26.71,20.29Z"></path>
      <path d="M38,20H30a1,1,0,0,0,0,2h8A1,1,0,0,0,38,20Z"></path>
      <path d="M25.8,24a1,1,0,0,0-.18,1.9,1,1,0,0,0,1.3-.54A1,1,0,0,0,25.8,24Z"></path>
      <path d="M38,24H30a1,1,0,0,0,0,2h8A1,1,0,0,0,38,24Z"></path>
    </g>
  </svg>
);
const dentalCare = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>Dental care</title>
    <g id="Dental_care" data-name="Dental care">
      <path d="M48,13C48-.15,30.49-5,23.78,6.47c-2.27.5-4.3,1.79-7.7.52A19.37,19.37,0,0,0,9.4,6C5.11,6,0,8,0,17.33c0,6,3.67,9.59,4.83,14,.75,2.84.53,9.23,1.59,14a3.43,3.43,0,0,0,6.57.28c3-9.4,5.36-10.13,6-10.13s3,.73,6,10.13a3.43,3.43,0,0,0,6.57-.28,58.26,58.26,0,0,0,1-8.29c.39-5.87.43-6.13,3-11.14A13,13,0,0,0,48,13ZM31.23,30.86c-.8,3.06-.58,9.5-1.61,14.1a1.43,1.43,0,0,1-2.71.12C22,29.7,16,29.67,11.09,45.08A1.43,1.43,0,0,1,8.38,45c-1-4.59-.81-11-1.61-14.1C5.51,26.05,2,22.8,2,17.33,2,11.05,4.42,8,9.4,8a17.3,17.3,0,0,1,6,.87,10.39,10.39,0,0,0,7.31,0,13,13,0,0,0,10.62,17A25,25,0,0,0,31.23,30.86ZM35,24A11,11,0,1,1,46,13,11,11,0,0,1,35,24Z"></path>
      <path d="M42,10H38V6a1,1,0,0,0-1-1H33a1,1,0,0,0-1,1v4H28a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V16h4a1,1,0,0,0,1-1V11A1,1,0,0,0,42,10Zm-1,4H37a1,1,0,0,0-1,1v4H34V15a1,1,0,0,0-1-1H29V12h4a1,1,0,0,0,1-1V7h2v4a1,1,0,0,0,1,1h4Z"></path>
    </g>
  </svg>
);

const anestesia = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>anesthesia</title>
    <g id="anesthesia">
      <path d="M47,46H1a1,1,0,0,0,0,2H47A1,1,0,0,0,47,46Z"></path>
      <path d="M1.89,33.45a6.23,6.23,0,0,1,3-2.89,11.07,11.07,0,0,1,.79,1.95c.39,1.48.28,5.06.88,7.71a2.36,2.36,0,0,0,4.51.19c1.38-4.31,2.57-5.26,2.91-5.26s1.53,1,2.91,5.26a2.36,2.36,0,0,0,4.51-.19A31.41,31.41,0,0,0,22,35.58c.2-3,.22-3.26,1.42-5.58h3.41L25.67,32a1,1,0,0,0,.87,1.5c.82,0,.95-.65,2.56-3.45H47a1,1,0,0,0,0-2H30.26c1-1.68.55-1.21,1.27-1.21a3,3,0,0,0,2.6-1.51c.8-1.37.41-1,1.13-1a3,3,0,0,0,2.61-1.5l4.59-8a3,3,0,0,0,3.44-3.71c-.37-1.38-1.38-1.81-2.27-2.32l2-3.46.87.5a1,1,0,0,0,1-1.74l-6.93-4a1,1,0,0,0-1,1.74l.87.5-2,3.46-.87-.5A3,3,0,0,0,33.8,9.87c-.64,1.09-1.65,2.86-1.59,2.75-2,4-4.74,6.21-2.68,8.64-.64,1.11-1.76,2.72-.27,4.47L28,28H24.32c1.68-4.22.51-10-5-10-4.17,0-4.37,1.47-6.8.58A11.13,11.13,0,0,0,8.67,18C3,18,1.84,24.13,4,28.78a8.24,8.24,0,0,0-3.9,3.77A1,1,0,0,0,1.89,33.45ZM32.4,24.28a1,1,0,0,1-1.73-1l.5-.86,1.73,1Zm3.73-2.46a1,1,0,0,1-1.36.37l-3.47-2a1,1,0,0,1-.36-1.37l.5-.87c1.85,1.08,1.86,1.14,2.23,1.14a1,1,0,0,0,.5-1.87l-1.73-1,1-1.73c.94.54,1,.63,1.36.63a1,1,0,0,0,.5-1.86l-.86-.5,1-1.73,5.19,3Zm6-18.45,1.73,1-2,3.46-1.73-1Zm-6.1,3.56c.36,0-.22-.29,7.43,4.13a1,1,0,0,1-1,1.73c-.1,0,.87.5-6.93-4A1,1,0,0,1,36.07,6.93ZM8.67,20c3.55,0,4.44,1.57,7.49.45A9.39,9.39,0,0,1,19.33,20C21.87,20,23,21.44,23,24.68c0,2.87-1.92,4.62-2.63,7.32-.43,1.62-.37,5.41-.9,7.78a.36.36,0,0,1-.65,0c-2.8-8.75-6.74-9.06-9.64,0a.36.36,0,0,1-.65,0C8,37.36,8.09,33.72,7.63,32,6.92,29.3,5,27.56,5,24.68,5,21.44,6.13,20,8.67,20Z"></path>
      <path d="M21.14,14.51a1,1,0,0,0,1.72-1l-3-5a1,1,0,0,0-1.72,1Z"></path>
      <path d="M27,13.2l1-5a1,1,0,0,0-2-.4l-1,5A1,1,0,0,0,27,13.2Z"></path>
    </g>
  </svg>
);

// adults
const prophy = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>milk tooth</title>
    <g id="milk_tooth" data-name="milk tooth">
      <path d="M47,37H1a1,1,0,0,0,0,2H47A1,1,0,0,0,47,37Z"></path>
      <path d="M1.89,24.45a6.23,6.23,0,0,1,3-2.89,11.07,11.07,0,0,1,.79,2c.39,1.48.28,5.06.88,7.71a2.36,2.36,0,0,0,4.51.19c1.38-4.31,2.57-5.26,2.91-5.26s1.53,1,2.91,5.26a2.36,2.36,0,0,0,4.51-.19A31.41,31.41,0,0,0,22,26.58c.2-3,.22-3.26,1.42-5.58h3c1,2.94,1.75,2.24,2,6.58a25.7,25.7,0,0,0,.44,3.81,2.07,2.07,0,0,0,4,.18c1-3.25,1.9-4.12,2.14-4.17s1.12.92,2.14,4.17a2,2,0,0,0,2,1.43c3.17,0,2-5.15,2.73-7.9a29.93,29.93,0,0,1,1.51-3.41,6.12,6.12,0,0,1,2.77,2.76,1,1,0,0,0,1.34.44c1.79-.89-1.28-4-3.08-4.93-.71-.38-.37.07-.37-1.31C44,13.73,41,13,39.27,13a9.16,9.16,0,0,0-3.17.48c-1.8.68-2.05-.48-5.37-.48S25.85,15.57,26,19h-1.7c1.68-4.22.51-10-5-10a11.13,11.13,0,0,0-3.86.58c-2.35.85-2.76-.58-6.8-.58C3,9,1.84,15.13,4,19.78a8.24,8.24,0,0,0-3.9,3.77A1,1,0,0,0,1.89,24.45ZM30.73,15c2.72,0,3.62,1.28,6.07.36A7.09,7.09,0,0,1,39.27,15C41.16,15,42,16.13,42,18.65c0,2.28-1.53,3.73-2.1,6C39.54,26,39.61,29,39.17,31c-.34.35-1.28-5.58-4.17-5.58s-3.86,6-4.18,5.56c-.44-2-.34-4.9-.72-6.36-.57-2.22-2.1-3.66-2.1-6C28,16.13,28.84,15,30.73,15ZM8.67,11c3.55,0,4.44,1.57,7.49.45A9.39,9.39,0,0,1,19.33,11C21.87,11,23,12.44,23,15.68c0,2.87-1.92,4.62-2.63,7.32-.43,1.62-.37,5.41-.9,7.78a.35.35,0,0,1-.65,0c-2.8-8.75-6.74-9.06-9.64,0a.35.35,0,0,1-.65,0C8,28.36,8.09,24.72,7.63,23,6.92,20.3,5,18.56,5,15.68,5,12.44,6.13,11,8.67,11Z"></path>
    </g>
  </svg>
);

const orthodontic = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>orthodontic</title>
    <g id="orthodontic">
      <path d="M1,18H3.33A11.73,11.73,0,0,0,4,19.78a8.24,8.24,0,0,0-3.9,3.77,1,1,0,0,0,1.78.9,6.23,6.23,0,0,1,3-2.89,11.07,11.07,0,0,1,.79,2c.39,1.48.29,5.06.88,7.71a2.36,2.36,0,0,0,4.51.19c1.38-4.31,2.57-5.26,2.91-5.26s1.53,1,2.91,5.26a2.36,2.36,0,0,0,4.51-.19A31.41,31.41,0,0,0,22,26.58c.28-4.13.43-3.36,2-6.82,1.62,3.47,1.76,2.69,2,6.82a31.41,31.41,0,0,0,.54,4.64,2.36,2.36,0,0,0,4.51.19c1.38-4.31,2.57-5.26,2.91-5.26s1.53,1,2.91,5.26a2.36,2.36,0,0,0,4.51-.19c.94-4.17,0-6.3,1.67-9.66a6.23,6.23,0,0,1,3,2.89,1,1,0,0,0,1.78-.9A8.24,8.24,0,0,0,44,19.78,11.73,11.73,0,0,0,44.67,18H47a1,1,0,0,0,0-2c-2.4,0-2,.11-2-.32C45,9.87,41.45,9,39.33,9a11.13,11.13,0,0,0-3.86.58c-2.35.86-2.76-.58-6.8-.58A5.23,5.23,0,0,0,24,11.38,5.23,5.23,0,0,0,19.33,9c-4.18,0-4.36,1.47-6.8.58A11.13,11.13,0,0,0,8.67,9C6.55,9,3,9.87,3,15.68c0,.43.42.32-2,.32A1,1,0,0,0,1,18Zm19,8.45a30,30,0,0,1-.49,4.33.36.36,0,0,1-.65,0c-2.8-8.75-6.74-9.06-9.64,0a.36.36,0,0,1-.65,0A30,30,0,0,1,8,26.45C7.66,20.77,6.55,21.09,5.43,18H10a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2h4.57C21.39,21.24,20.35,20.75,20,26.45ZM16,18H12V16h4Zm24,8.45a30,30,0,0,1-.49,4.33.36.36,0,0,1-.65,0c-2.8-8.75-6.74-9.06-9.64,0a.36.36,0,0,1-.65,0A30,30,0,0,1,28,26.45c-.38-5.68-1.49-5.36-2.61-8.45H30a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2h4.57C41.39,21.24,40.35,20.75,40,26.45ZM36,18H32V16h4Zm-7.33-7c3.55,0,4.44,1.57,7.49.45A9.35,9.35,0,0,1,39.33,11c2.91,0,3.81,2,3.65,5H38a2,2,0,0,0-2-2H32a2,2,0,0,0-2,2H25C24.86,13,25.77,11,28.67,11Zm-20,0c3.55,0,4.44,1.57,7.49.45A9.35,9.35,0,0,1,19.33,11c2.91,0,3.81,2,3.65,5H18a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2H5C4.86,13,5.77,11,8.67,11Z"></path>
      <path d="M47,37H1a1,1,0,0,0,0,2H47A1,1,0,0,0,47,37Z"></path>
    </g>
  </svg>
);

const smile = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>smile with teeth</title>
    <g id="smile_with_teeth" data-name="smile with teeth">
      <path d="M47.14,12c-7-1-16.18-1.6-23.14,0C17.05,10.41,7.92,11,.86,12A1,1,0,0,0,0,13.17C.06,13.41,4.39,37,24,37S47.94,13.41,48,13.17A1,1,0,0,0,47.14,12ZM2.21,13.85c16.27-2,21.11.35,22,.12,6.3-1.57,15.11-.93,21.55-.12C39,42.15,9,42.21,2.21,13.85Z"></path>
      <path d="M6.18,15a1,1,0,0,0-1.09,1.4C5.35,17,11.5,30,24,30S42.65,17,42.91,16.42A1,1,0,0,0,41.82,15C27.76,17.59,20.25,17.59,6.18,15ZM12,18c1.25.17,2.61.35,4,.5V21a1,1,0,0,1-1,1H13a1,1,0,0,1-1-1Zm10,5H19a1,1,0,0,1-1-1V18.67c1.72.16,3.43.28,5,.32v3A1,1,0,0,1,22,23Zm-7,1a1,1,0,0,1,1,1v.72A19.72,19.72,0,0,1,13.56,24Zm3,2a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1V28a15.1,15.1,0,0,1-5-1.2Zm7,0a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v.75A15.1,15.1,0,0,1,25,28Zm7-1a1,1,0,0,1,1-1h1.44A19.72,19.72,0,0,1,32,25.72Zm4-4a1,1,0,0,1-1,1H33a1,1,0,0,1-1-1V18.46c1.39-.15,2.75-.33,4-.5Zm-6,1a1,1,0,0,1-1,1H26a1,1,0,0,1-1-1V19c1.57,0,3.28-.16,5-.32ZM10,17.67c0,2.6,0,2.42-.05,2.62a29.72,29.72,0,0,1-2.07-2.95Zm28.05,2.62c-.08-.23,0-.18,0-2.62l2.12-.33A29.72,29.72,0,0,1,38.05,20.29Z"></path>
    </g>
  </svg>
);

const girl = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <title>girl patient</title>
    <g id="girl_patient" data-name="girl patient">
      <path d="M21.29,22.29a1,1,0,0,0,1.42,1.42l.29-.3a1.48,1.48,0,0,1,2,0c.35.35.54.59,1,.59a1,1,0,0,0,.71-1.71A3.56,3.56,0,0,0,21.29,22.29Z"></path>
      <path d="M33.29,28.71l1,1a1,1,0,0,0,1.42-1.42l-1-1A1,1,0,0,0,33.29,28.71Z"></path>
      <path d="M37.33,26.7a1,1,0,0,0,.26-2l-1.37-.37a1,1,0,0,0-.51,1.94A8.41,8.41,0,0,0,37.33,26.7Z"></path>
      <path d="M36.59,23,38,22.64a1,1,0,0,0-.52-1.94l-1.37.37A1,1,0,0,0,36.59,23Z"></path>
      <path d="M39.09,32.32,28,29.87V28a9.52,9.52,0,0,0,.87-.52,4.51,4.51,0,0,0,3.65-7.79l0-.67h.94A3.51,3.51,0,0,0,36,13.05V12a12,12,0,0,0-24,0V31.64l-3.09.68A5,5,0,0,0,5,37.21V47a1,1,0,0,0,2,0V37.21a3,3,0,0,1,2.35-2.93L15,33V47a1,1,0,0,0,2,0V33.41C23.84,39.17,23.48,39,24,39s.1.22,7-5.59V47a1,1,0,0,0,2,0V33l5.65,1.26A3,3,0,0,1,41,37.21V47a1,1,0,0,0,2,0V37.21A5,5,0,0,0,39.09,32.32Zm-10.22-6.9C27.8,25.15,27.19,27,24,27A6.48,6.48,0,0,1,17.56,21l-.49-7.87c2.23.07,7-.18,9.84-3.29a7.62,7.62,0,0,0,4,3.08c0,.12-.38,6.07-.45,7.2S32,21.11,32,23A2.5,2.5,0,0,1,28.87,25.42ZM33.5,17h-.81l.19-3c.69,0,1.18-.07,1.68.44A1.5,1.5,0,0,1,33.5,17ZM14,12A10,10,0,0,1,31.07,4.93,9.91,9.91,0,0,1,34,12.05,4.66,4.66,0,0,0,33,12a1,1,0,0,0-1-1c-1.55,0-3.27-1.78-4.11-3.45a1,1,0,0,0-1.75-.06C23.52,11.86,16.2,11,16.12,11A1,1,0,0,0,15,12.06l.56,9A8.45,8.45,0,0,0,20,28v1.88L14,31.2ZM24,36.69l-5.33-4.48,1.77-.39A2,2,0,0,0,22,29.87V28.75a8,8,0,0,0,4,0v1.13a2,2,0,0,0,1.56,1.95l1.77.39Z"></path>
      <path d="M23.29,41.29a1,1,0,0,0,1.09,1.63A1,1,0,1,0,23.29,41.29Z"></path>
      <path d="M24.92,45.62a1,1,0,1,0-1.47,1.21A1,1,0,0,0,24.92,45.62Z"></path>
    </g>
  </svg>
);

const AboutSection = () => {
  const navigate = useNavigate();

  const { selectedFlow } = useContext(Context);

  const isOrtho = selectedFlow === "ortho";

  const heading = isOrtho
    ? "Kieferorthopädie für jedes Alter"
    : "Kinder- und Jugendzahnheilkunde";

  const heading2 = isOrtho ? "" : " von 4 bis 17 Jahre";

  const text = isOrtho
    ? "Von unsichtbaren Invisalign-Alignern über herausnehmbaren Zahnspangen bis hin zu festsitzenden Brackets stellen wir Ihnen alle Möglichkeiten der modernen Kieferorthopädie zur Verfügung."
    : "Heranwachsende sind bei uns bestens aufgehoben. Für allgemeine Vorsorge und Erkrankungen gehen Sie auch lieber zum Kinderarzt und nicht zum Hausarzt. Wir freuen uns als Kinder- und Jugendzahnarztpraxis auf den Besuch Ihrer Kinder!";

  const about1 = isOrtho ? aboutThumb1 : aboutThumbKids1;
  const about2 = isOrtho ? aboutThumb2 : aboutThumbKids2;
  const about3 = isOrtho ? aboutThumb3 : aboutThumbKids3;
  const about4 = isOrtho ? aboutThumb4 : aboutThumbKids4;

  const first = isOrtho ? "Herausnehmbare Zahnspangen" : "Prophylaxe";
  const second = isOrtho ? "Brackets" : "Diagnostik";
  const third = isOrtho ? "Invisalign-Aligner" : "Behandlungen";
  const fourth = isOrtho ? "CMD und Bruxismus" : "Sedierung / Narkose";

  const firstSvg = isOrtho ? prophy : floss;
  const secondSvg = isOrtho ? orthodontic : computer;
  const thirdSvg = isOrtho ? smile : dentalCare;
  const fourthSvg = isOrtho ? girl : anestesia;

  return (
    <>
      <section className="section-sp1 about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-30">
              <div className="about-thumb-area">
                <ul>
                  <li>
                    <img className="about-thumb1" src={about1} alt="" />
                  </li>
                  <li>
                    <img className="about-thumb3" src={about3} alt="" />
                  </li>
                  <li>
                    <img
                      className="about-thumb2"
                      style={{ objectFit: "cover" }}
                      src={about2}
                      alt=""
                    />
                  </li>
                  <li>
                    <div className="exp-bx">
                      <img src={about4} alt="" />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="heading-bx">
                <h6 className="title-ext text-secondary">Unsere Leistungen</h6>
                <h2 className="title">
                  {heading}
                  <span>
                    <h3>{heading2}</h3>
                  </span>
                </h2>
                <p>{text}</p>
              </div>
              <div className="row">
                <div
                  onClick={() => {
                    navigate(isOrtho ? "/zahnspangen" : "/prophylaxe");
                  }}
                  className="col-lg-6 col-sm-6 mb-30 mb-sm-20"
                >
                  <div className="feature-container feature-bx1 feature1">
                    <div className="icon-md">
                      <span className="icon-cell">{firstSvg}</span>
                    </div>
                    <div className="icon-content">
                      <h4 className="ttr-title">{first}</h4>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate(isOrtho ? "/kassenbehandlung" : "/diagnostik");
                  }}
                  className="col-lg-6 col-sm-6 mb-30 mb-sm-20"
                >
                  <div className="feature-container feature-bx1 feature2">
                    <div className="icon-md">
                      <span className="icon-cell">{secondSvg}</span>
                    </div>
                    <div className="icon-content">
                      <h4 className="ttr-title">{second}</h4>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate(isOrtho ? "/privatbehandlung" : "/behandlungen");
                  }}
                  className="col-lg-6 col-sm-6 mb-30 mb-sm-20"
                >
                  <div className="feature-container feature-bx1 feature3">
                    <div className="icon-md">
                      <span className="icon-cell">{thirdSvg}</span>
                    </div>
                    <div className="icon-content">
                      <h4 className="ttr-title">{third}</h4>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate(isOrtho ? "/cmd-bruxismus" : "/sedierung");
                  }}
                  className="col-lg-6 col-sm-6 mb-30 mb-sm-20"
                >
                  <div className="feature-container feature-bx1 feature4">
                    <div className="icon-md">
                      <span className="icon-cell">{fourthSvg}</span>
                    </div>
                    <div className="icon-content">
                      <h4 className="ttr-title">{fourth}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="pt-img1 animate-wave" src={ptImg1} alt="" />
        <img className="pt-img2 animate2" src={ptImg2} alt="" />
        <img className="pt-img3 animate-rotate" src={ptImg5} alt="" />
        <img className="pt-img4 animate-wave" src={ptImg4} alt="" />
        <img className="pt-img5 animate2" src={ptImg5} alt="" />
      </section>
    </>
  );
};

export default AboutSection;
