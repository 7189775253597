import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

// Import Images
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import waveBlue from "../../images/shap/wave-blue.png";
import squareRotate from "../../images/shap/square-rotate.png";
import { Context } from "../../context";

const ServicesSliderSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 591,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { selectedFlow } = useContext(Context);
  const isOrtho = selectedFlow === "ortho";

  return (
    <>
      <section className="section-area section-sp1 service-wraper">
        <div className="row align-items-center">
          <div className="col-xl-4 col-lg-7 mb-30">
            <div className="heading-bx">
              <h6 className="title-ext text-secondary">Finanzen</h6>
              <h2 className="title">
                Bezahlbare {isOrtho ? "Kieferorthopädie" : "Behandlungen"}
              </h2>
            </div>
            {/* <Link to="/services" className="btn btn-secondary btn-lg shadow">
              All Services
            </Link> */}
          </div>
          <div className="col-xl-8 mb-15">
            <Slider {...settings} className="service-slide slick-arrow-none">
              <div className="slider-item">
                <div className="feature-container feature-bx2 feature1">
                  <div className="feature-box-xl mb-30">
                    <span className="icon-cell">
                      <svg
                        id="Line"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <path
                          d="m489.61,147.99c-4.32-55.64-46.6-97.59-98.35-97.59-17.98,0-34.71,1.84-49.85,5.42-3.17-3.3-7.09-5.92-11.57-7.55l-69.32-25.21c-6.74-2.45-14.17-2.44-20.91,0l-69.32,25.21c-5,1.82-9.3,4.89-12.65,8.76-15.13-4.4-31.51-6.63-48.78-6.63-51.64,0-92.99,40.97-98.33,97.43-3.01,32.26,6.89,60.52,19.43,96.3,14.57,41.59,32.71,93.35,39.89,175.69,3.22,36.9,39.31,58.95,72.34,58.95,13.09,0,26.01-3.51,37.36-10.16,11.31-6.62,20.68-16.15,27.09-27.57l14.99-26.72c5.83-10.43,16.46-16.66,28.42-16.66s22.58,6.23,28.42,16.67l14.99,26.72c13.11,23.26,37.8,37.71,64.44,37.71,16.63,0,33-5.74,46.11-16.18,15.82-12.6,24.89-27.39,26.23-42.77,7.25-83.11,25.34-135.24,39.89-177.13,12.25-35.3,21.93-63.18,19.45-94.69ZM170.55,77.02c0-4.27,2.7-8.12,6.71-9.58l69.32-25.21c1.12-.41,2.3-.62,3.48-.62s2.36.2,3.48.62l69.32,25.21c4.01,1.46,6.71,5.31,6.71,9.58v60.48c0,34.55-17.49,66.1-46.78,84.4l-27.33,17.08c-3.29,2.05-7.53,2.05-10.81,0l-27.33-17.08c-29.29-18.3-46.78-49.86-46.78-84.4v-60.48Zm280.34,158.98c-14.91,42.96-33.47,96.42-40.93,182.05-1.07,12.22-10.72,22.29-18.63,28.59-9.51,7.57-21.37,11.74-33.41,11.74-19.29,0-37.18-10.47-46.67-27.31l-14.98-26.7c-9.49-16.96-26.76-27.09-46.21-27.09s-36.72,10.13-46.2,27.08l-14.98,26.7c-4.63,8.26-11.41,15.16-19.61,19.95-8.23,4.82-17.59,7.37-27.06,7.37-23.33,0-49.86-15.42-52.03-40.33-7.4-84.88-26.01-137.99-40.96-180.66-12.18-34.74-20.97-59.84-18.37-87.65,4.33-45.75,37.14-78.95,78.03-78.95,14.67,0,28.53,1.8,41.33,5.33,0,.3-.04.6-.04.9v60.48c0,41.62,21.07,79.64,56.36,101.7l27.33,17.08c4.92,3.08,10.56,4.61,16.21,4.61s11.28-1.54,16.21-4.61l27.33-17.08c35.29-22.06,56.36-60.08,56.36-101.7v-60.48c0-.74-.03-1.47-.09-2.21,12.62-2.66,26.48-4.02,41.38-4.02,41.66,0,74.48,33.13,78.02,78.79,2.15,27.27-6.47,52.08-18.39,86.42Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                        <path
                          d="m209.51,142.25h30.47v30.47c0,5.63,4.56,10.19,10.19,10.19s10.19-4.56,10.19-10.19v-30.47h30.47c5.63,0,10.19-4.56,10.19-10.19s-4.56-10.19-10.19-10.19h-30.47v-30.47c0-5.63-4.56-10.19-10.19-10.19s-10.19,4.56-10.19,10.19v30.47h-30.47c-5.63,0-10.19,4.56-10.19,10.19s4.56,10.19,10.19,10.19Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">
                      Zusatz-
                      <br />
                      versicherung
                    </h3>
                    <p>
                      Heutzutage ein Muss bei gesetzlich Versicherten. Diese
                      sollte unbedingt (!) vor dem Erstbesuch abgeschlossen
                      werden.
                    </p>
                    <Link
                      to="/zusatzversicherung"
                      className="btn btn-primary light"
                    >
                      Mehr
                    </Link>
                  </div>
                </div>
              </div>
              <div className="slider-item">
                <div className="feature-container feature-bx2 feature2">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        id="Line"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <path
                          d="m472.42,144.73c-25.93-39.01-87.67-103.8-212.43-106.98v-18.15c0-5.31-4.3-9.6-9.6-9.6s-9.6,4.29-9.6,9.6v18.15c-125.75,3.2-187.46,68.78-213.3,108.27-4.55,6.96-4.28,15.75.7,22.41,5,6.69,13.42,9.46,21.4,7.07h0l8.67-2.61c31.2-9.37,64.67-7.01,94.25,6.61l2.19,1.01c4.26,1.95,9.07,2.32,13.6,1.05l40.95-11.71c10.01-2.86,20.23-4.65,30.5-5.38v86.46c-61,5.49-108.97,56.87-108.97,119.27,0,66.06,53.74,119.79,119.79,119.79s119.79-53.74,119.79-119.79-49.26-115.14-111.41-119.46v-86.39c10.96.63,21.87,2.45,32.54,5.5l41.29,11.8c1.75.5,3.56.74,5.37.74,2.43,0,4.87-.44,7.13-1.33l12.86-5.02c29.28-11.43,61.78-12.14,91.53-2.01,8.1,2.77,16.7.15,21.93-6.63,5.17-6.73,5.49-15.64.8-22.69Zm-121.25,225.48c0,55.47-45.12,100.59-100.59,100.59s-100.59-45.12-100.59-100.59,45.13-100.58,100.59-100.58,100.59,45.12,100.59,100.58Zm.02-212.04l-13.12,5.03-41.29-11.8c-30.35-8.66-62.43-8.66-92.79,0l-41.25,11.69-2.19-1.01c-33.82-15.59-72.11-18.27-107.8-7.57l-8.67,2.61,2.76,9.2-3.28-9.76c24.36-37.25,83.63-99.72,206.83-99.72s181.58,61.73,205.5,99.06c-34.02-11.61-71.21-10.79-104.69,2.29Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                        <path
                          d="m240.36,303.01v11.93h-.38c-15.33,0-27.8,12.47-27.8,27.8v9.68c0,15.33,12.47,27.8,27.8,27.8h.38v26.88h-.38c-4.74,0-8.6-3.85-8.6-8.91,0-5.31-4.3-9.45-9.6-9.45s-9.6,4.45-9.6,9.76c0,15.33,12.47,27.8,27.8,27.8h.38v11.11c0,5.31,4.3,9.6,9.6,9.6s9.6-4.29,9.6-9.6v-11.11h1.63c15.33,0,27.8-12.47,27.8-27.8v-9.68c0-15.33-12.47-27.8-27.8-27.8h-1.63v-26.88h1.63c4.74,0,8.6,3.85,8.6,8.6,0,5.31,4.3,9.6,9.6,9.6s9.6-4.29,9.6-9.6c0-15.33-12.47-27.8-27.8-27.8h-1.63v-11.93c0-5.31-4.3-9.6-9.6-9.6s-9.6,4.29-9.6,9.6Zm20.83,77.21c4.74,0,8.6,3.85,8.6,8.6v9.68c0,4.74-3.86,8.6-8.6,8.6h-1.63v-26.88h1.63Zm-20.83-19.2h-.38c-4.74,0-8.6-3.85-8.6-8.6v-9.68c0-4.74,3.86-8.6,8.6-8.6h.38v26.88Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Ratenzahlung</h3>
                    <p>
                      Wir bieten Ihnen die Möglichkeit, in Raten zu zahlen,
                      damit auch die bestmögliche Behandlung in Ihr Budget
                      passt.
                    </p>
                    <Link to="/ratenzahlung" className="btn btn-primary light">
                      Mehr
                    </Link>
                  </div>
                </div>
              </div>
              <div className="slider-item">
                <div className="feature-container feature-bx2 feature3">
                  <div className="feature-box-xl mb-20">
                    <span className="icon-cell">
                      <svg
                        id="Line"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 500 500"
                      >
                        <path
                          d="m421.56,62.87L258.42,11.35c-5.71-1.8-11.75-1.8-17.46,0L77.81,62.87c-14.25,4.5-23.82,17.57-23.82,32.5v146.06c0,82.33,41.68,157.53,111.5,201.16l68.83,43.02c4.67,2.92,10.02,4.38,15.37,4.38s10.7-1.46,15.36-4.38l68.84-43.02c69.82-43.63,111.5-118.84,111.5-201.16V95.37c0-14.94-9.57-28-23.82-32.5Zm4.62,178.57c0,75.66-38.31,144.78-102.47,184.88l-68.84,43.03c-3.15,1.97-7.21,1.97-10.37,0l-68.84-43.02c-64.16-40.1-102.47-109.21-102.47-184.88V95.37c0-6.52,4.18-12.23,10.41-14.19L246.75,29.66c.96-.3,1.95-.46,2.94-.46s1.98.15,2.94.46l163.15,51.52c6.23,1.97,10.41,7.67,10.41,14.19v146.06Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                        <path
                          d="m353.71,159.64c-4.11-3.35-10.15-2.76-13.51,1.35l-104.05,127.18-66.02-55.02c-4.07-3.4-10.12-2.84-13.52,1.23-3.39,4.07-2.85,10.13,1.23,13.52l73.46,61.22c1.73,1.44,3.91,2.23,6.15,2.23.3,0,.61-.01.91-.04,2.55-.24,4.89-1.5,6.52-3.48l110.19-134.67c3.36-4.11,2.75-10.15-1.35-13.51Z"
                          fill="#414042"
                          stroke-width="0"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="icon-content">
                    <h3 className="ttr-title">Kostenerstattung</h3>
                    <p>
                      Wir lassen Sie bei Erstattungsproblemen nicht allein! Wir
                      unterstützen Sie kostenfrei mit rechtssicheren
                      Stellungnahmen.
                    </p>
                    <Link
                      to="/kostenerstattung"
                      className="btn btn-primary light"
                    >
                      Mehr
                    </Link>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <img className="pt-img1 animate-rotate" src={lineCircleBlue} alt="" />
        <img className="pt-img2 animate2" src={squareDotsOrange} alt="" />
        <img className="pt-img3 animate-wave" src={waveBlue} alt="" />
        <img className="pt-img4 animate1" src={squareRotate} alt="" />
      </section>
    </>
  );
};

export default ServicesSliderSection;
