import React from "react";
import Banner from "../elements/Banner";

import bruxismus2 from "../../images/private-treatment/bruxismus2.jpg";
import workflow from "../../images/treatment/workflow.jpeg";
import scan from "../../images/teeth-scan.jpg";
import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function Diagnostic() {
  return (
    <React.Fragment>
      <PageTitle
        title="Diagnostik"
        description="Röntgen, Infrarot, 3D"
        canonical="diagnostik"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Diagnostik " />
          <div className="container mt-30">
            <div className="diagnostic-text-with-image">
              <div>
                <h4>Röntgen</h4>
                <p className="text-justify mt-30">
                  Die Diagnostik beim Zahnarzt umfasst eine gründliche
                  Untersuchung des Mundraums, der Zähne und des Zahnfleisches,
                  unterstützt durch Röntgenaufnahmen und andere bildgebende
                  Verfahren. Das Ziel ist die frühzeitige Erkennung von
                  Zahnproblemen wie Karies, Zahnfleischerkrankungen oder anderen
                  oralen Pathologien, um eine gezielte Behandlung zu ermöglichen
                  und langfristige Mundgesundheit zu fördern.
                </p>
              </div>
              <img src={bruxismus2} style={{ borderRadius: "2rem" }} />
            </div>

            <div className="diagnostic-text-with-image--reverse mt-60">
              <div>
                <h4>Strahlungsfreie Infrarot-Diagnostik</h4>

                <p className="text-justify mt-30">
                  Hierzu bieten wir eine innovative Infrarot-Kariesdiagnostik
                  mittels unseres iTero-Intraoralscanners an. Hierbei fertigen
                  wir digitale Abformungen der Zähne Ihres Kindes an und
                  durchleuchten diese währenddessen mithilfe von Infrarot. Im
                  Anschluss können wir uns Bilder anschauen, die oftmals noch
                  genauer und besser zu beurteilen sind als Röntgenbilder. Und
                  das komplett ohne schädliche Röntgenstrahlung. Wir empfehlen,
                  das Karies-Screening als sinnvolle Ergänzung zur
                  Sichtkontrolle zu jeder halbjährlichen Untersuchung.{" "}
                </p>

                <p>
                  Da die Kosten leider nicht von der gesetzlichen
                  Krankenversicherung getragen werden, ist diese Privatleistung
                  von ca. 49 € selbst zu tragen.
                </p>
              </div>
              <img src={workflow} />
            </div>

            <div className="diagnostic-text-with-image mt-60">
              <div>
                <h4>Digitale Volumentomografie</h4>
                <p className="text-justify mt-30">
                  Manchmal müssen wir dennoch zusätzlich röntgen, ob als
                  Übersichtsaufnahme, als Seitenaufnahme oder als Zahnfilm. Wir
                  klären hierbei jedes Mal ausgiebig aus, warum wir welche
                  Bilder machen müssen. Röntgenbilder werden in der Regel von
                  der gesetzlichen Krankenkasse bezahlt.
                </p>

                <p className="text-justify">
                  Es kommt jedoch häufiger vor, dass eine 3D-Aufnahme notwendig
                  wird. Dies kann zum Beispiel bei verlagerten Eck- und/oder
                  Weisheitszähnen der Fall sein. Diese Leistung wird wiederum
                  nicht von der gesetzlichen Krankenversicherung bezahlt und
                  kostet Sie rund 250 €.
                </p>
              </div>
              <img src={scan} />
            </div>
          </div>
          <BookingButton
            classes="btn-primary mt-50 d-block mx-auto"
            text="Hier geht es zur Terminbuchung"
          />
        </section>
      </div>
    </React.Fragment>
  );
}
