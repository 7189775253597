import React from "react";
import Banner from "../elements/Banner";
import karriere from "../../images/karriere.jpg";
import karriere2 from "../../images/karriere-2.jpg";
import useWindowDimensions from "../../useWindowDimensions";
import PageTitle from "../elements/PageTitle";

export default function Karriere() {
  const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="Karriere"
        description="Komm an Bord und flieg hoch hinaus!"
        canonical="karriere"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Karriere" />
          <div className="container">
            <h3 className="text-center mt-40">
              Komm an Bord und flieg hoch hinaus!
            </h3>
            <img
              src={karriere}
              style={{
                borderRadius: "2rem",
                marginTop: "2rem",
                height: "400px",
                objectFit: "cover",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            />
            <p className="text-justify">
              <br />
              <strong style={{ fontWeight: 600 }}>
                Du bist zahnmedizinische Fachangestellte (ZFA), Prophylaxekraft
                (z. B. ZMP), Dentalhygienikerin oder ZMF,
                Verwaltungsfachangestellte (ZMV) oder Hygienekraft? Dann
                aufgepasst.
              </strong>
              <br />
              <br />
              Wir sind ein junges, dynamisches Duo, welches zum 01.10.2024 im
              Raum Braunschweig und Umgebung eine einmalige Praxis eröffnet.
              <br />
              <br />
              Dafür stellen wir jetzt unser gesamtes Team auf. Vielleicht
              gehörst auch Du dazu.
              <br />
              <br />
              Wir bieten Kieferorthopädie sowie Kinder- und Jugendzahnheilkunde
              auf dem neusten Stand der Wissenschaft an. Unser Konzept der
              Fliegerei zieht sich durch unsere gesamten Räumlichkeiten.
              <br />
              <br />
              Außerdem kannst Du Dich auf wirklich (!) komplett digitalisierte
              Abläufe freuen. Mehr digital wirst Du wohl in keiner anderen
              Praxis in Deutschland finden!
              <br />
              <br />
              Mit Dir zusammen wollen wir hoch hinaus fliegen! Und damit Du
              keinen Jetlag bekommst, bieten wir sowohl flexible Teilzeit-, als
              auch Vollzeitmodelle an.
              <br />
              <br />
              Wenn Du mit uns abhebst, bieten wir, über die
              <strong style={{ fontWeight: 600 }}> Tarifbindung </strong>
              hinaus, Goodies in Form von E-Bike, Deutschlandticket oder einem
              Tankgutschein an, damit Du nicht nur fliegst.
              <br />
              <br />
              Für Deine Gesundheit sowie die deiner engsten Verwandten ist durch
              unseren Partner Fair Family gesorgt. Dazu bekommst Du ein
              jährliches Gesundheitsbudget, z. B. für Sehhilfen, Massagen,
              Physiotherapie, Facharztterminen innerhalb von 5 Tagen und vieles
              mehr!
              <br />
              <br />
              Außerdem erhalten Du und Deine engsten Angehörigen deutliche
              Vergünstigungen bei zahnärztlichen Behandlungen.
              <br />
              <br />
              Hast Du Lust und fühlst Dich angesprochen? Dann schick uns an{" "}
              <span>
                <u>
                  <a href="mailto:leitung@fliegender-zahn.de">
                    {" "}
                    leitung@fliegender-zahn.de
                  </a>
                </u>
              </span>{" "}
              Deinen Lebenslauf und wir melden uns bei Dir!
              <br />
              <br />
              <strong style={{ fontWeight: 600 }}>
                We are ready to take off! Du auch?
              </strong>
            </p>
            <img
              src={karriere2}
              style={{
                borderRadius: "2rem",
                marginTop: "2rem",
                height: "400px",
                objectFit: "cover",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            />
            <a
              href="mailto:leitung@fliegender-zahn.de"
              className={`btn btn-primary mt-20 mb-20 mx-auto d-block ${
                width < 768 ? "w-50" : "w-25"
              }`}
            >
              Hier bewerben
            </a>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
