import React, { useEffect, useState } from "react";
import Markup from "./markup/markup";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// Slick Carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Fonts
import "./vendor/line-awesome/css/line-awesome.min.css";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/themify/themify-icons.css";

// StyleSheet
import "./App.scss";
import Landing from "./markup/elements/Landing";
import { Context } from "./context";

function App() {
  const [selectedFlow, setSelectedFlow] = useState(null);

  // useEffect(() => {
  //   if (selectedFlow) {
  //     localStorage.setItem("selectedFlow", selectedFlow);
  //   }
  // }, [selectedFlow]);

  // check the url if different than / then set the selectedFlow
  useEffect(
    () => {
      const url = window.location.pathname;
      if (url !== "/") {
        setSelectedFlow("ortho");
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <HelmetProvider>
      <Context.Provider
        value={{
          selectedFlow,
          setSelectedFlow,
        }}
      >
        <BrowserRouter>
          <div className="page-wraper" style={{ position: "relative" }}>
            {selectedFlow ? (
              <div>
                <Markup />
              </div>
            ) : (
              <Landing setSelectedFlow={setSelectedFlow} />
            )}
          </div>
        </BrowserRouter>
      </Context.Provider>
    </HelmetProvider>
  );
}

export default App;
