import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// Elements
import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";

// Layout
import Header from "./layout/header";
import Footer from "./layout/footer";

// All Pages Router
import Index from "./pages/index";
import Services from "./pages/services";
import Faq from "./pages/faq";
import ContactUs from "./pages/Contacts";
import Error from "./pages/error-404";
import PublicTreatment from "./pages/PublicTreatment";
import PrivateTreatment from "./pages/PrivateTreatment";
import Bruxismus from "./pages/Bruxismus";
import LipInjection from "./pages/LipInjection";
import Insurance from "./pages/Insurance";
import Installment from "./pages/Installment";
import Reimbursement from "./pages/Reimbursement";
import Prophylaxis from "./pages/Prophylaxis";
import Diagnostic from "./pages/Diagnostic";
import Treatment from "./pages/Treatment";
import Sedation from "./pages/Sedation";
import Impressum from "./pages/Impressum";
import Karriere from "./pages/Karriere";

const Markup = () => {
  return (
    <>
      <Routes>
        <Route element={<ThemeLayout />}>
          <Route path="/" element={<Index />} />
          <Route path="/home" element={<Index />} />

          <Route path="/zahnspangen" element={<Services />} />
          <Route path="/kassenbehandlung" element={<PublicTreatment />} />
          <Route path="/privatbehandlung" element={<PrivateTreatment />} />
          <Route path="/cmd-bruxismus" element={<Bruxismus />} />
          <Route path="/lippenunterspritzung" element={<LipInjection />} />
          <Route path="/zusatzversicherung" element={<Insurance />} />
          <Route path="/ratenzahlung" element={<Installment />} />
          <Route path="/kostenerstattung" element={<Reimbursement />} />
          <Route path="/kontakt" element={<ContactUs />} />
          <Route path="/karriere" element={<Karriere />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/rechtliches" element={<Impressum />} />
          <Route path="/prophylaxe" element={<Prophylaxis />} />
          <Route path="/diagnostik" element={<Diagnostic />} />
          <Route path="/behandlungen" element={<Treatment />} />
          <Route path="/sedierung" element={<Sedation />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>

      <PageScrollTop />

      <BackToTop />
    </>
  );
};

function ThemeLayout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
export default Markup;
